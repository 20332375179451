import React from 'react';

const LinkArrow = () => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4243 5.42426C17.6586 5.18995 17.6586 4.81005 17.4243 4.57574L13.6059 0.757359C13.3716 0.523045 12.9917 0.523045 12.7574 0.757359C12.523 0.991674 12.523 1.37157 12.7574 1.60589L16.1515 5L12.7574 8.39411C12.523 8.62843 12.523 9.00833 12.7574 9.24264C12.9917 9.47696 13.3716 9.47696 13.6059 9.24264L17.4243 5.42426ZM0 5.6L17 5.6V4.4L0 4.4L0 5.6Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkArrow;
