import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const FadeIn = ({ children, visible, id, ...rest }) => (
  <AnimatePresence>
    {visible && (
      <motion.div
        key={id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        {...rest}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

export default FadeIn;
