import { useCallback, useEffect, useReducer, useRef } from 'react';
import { GraphQLClient } from 'graphql-request';

// FORMAT DATE
const formatDate = (date) =>
  `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')} ${String(
    date.getSeconds()
  ).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`;

// USE SAFE DISPATCH
// Stop render/fetching issues if item is unmounted before painted and therefore cannot update that state (MEMORY LEAK)
const useSafeDispatch = (dispatch) => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  return useCallback(
    (...args) => {
      if (mountedRef.current) dispatch(...args);
    },
    [dispatch]
  );
};

// ASYNC FETCH w/ STATUS
function asyncReducer(state, action) {
  switch (action.type) {
    case 'pending': {
      return { status: 'pending', data: null, error: null };
    }
    case 'resolved': {
      return { status: 'resolved', data: action.data, error: null };
    }
    case 'rejected': {
      return { status: 'rejected', data: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useAsyncFetch(initialState) {
  const [state, unsafeDispatch] = useReducer(asyncReducer, {
    status: 'idle',
    data: null,
    error: null,
    ...initialState,
  });

  const { data, error, status } = state;

  const dispatch = useSafeDispatch(unsafeDispatch);

  const run = useCallback(
    (promise) => {
      dispatch({ type: 'pending' });
      promise.then(
        (data) => {
          dispatch({ type: 'resolved', data });
        },
        (error) => {
          dispatch({ type: 'rejected', error });
        }
      );
    },
    [dispatch]
  );

  return {
    error,
    status,
    data,
    run,
  };
}

const DATOCMS_API_URL = `https://graphql.datocms.com/`;

const request = async ({ query, variables, preview }) => {
  const endpoint = preview ? `https://graphql.datocms.com/preview` : DATOCMS_API_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      authorization: `Bearer ${process.env.GATSBY_DATO_API_TOKEN}`,
    },
  });

  const response = await client.request(query, variables);

  if (response) {
    return response;
  }
  return Promise.reject(new Error(`No pokemon with the variable "${variables}"`));
};

export { useAsyncFetch, request, useSafeDispatch, formatDate };
