import React, { useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import tw from 'twin.macro';
import { useVideoModal } from 'utils/hooks/use-video-modal';
import Iframe from 'components/Iframe';
import Modal from 'components/Modal';
import TiltButton from 'components/TiltButton';
import { A } from 'components';

const H1 = tw.h1`text-white text-3xl font-bold text-green mt-10`;
const Grid = tw.ul`text-blue-dark grid grid-cols-2 md:grid-cols-4 gap-5`;
const Name = tw.h3`font-bold text-white text-2xl`;
const Controls = tw.div`relative flex justify-between items-center mt-10 flex-col lg:block`;
const Close = tw.button`text-red mb-8 font-semibold text-lg md:text-2xl`;

const Thumbnail = ({ imgSrc, hoverImgSrc, hasMouseOver }) => {
  return (
    <div tw="flex items-center justify-center overflow-hidden mb-2">
      {hasMouseOver ? <img src={hoverImgSrc} alt={name} /> : <img src={imgSrc} alt={name} />}
    </div>
  );
};

const Card = ({
  ecoid,
  ecospheres,
  twentythreeEmbedUrl,
  videoThumbnail,
  videoThumbnailHover,
  filters,
  id,
  name,
  oneLiner,
  onClickSetVideoIndex,
  website,
}) => {
  const [hasMouseOver, setHasMouseOver] = useState(false);
  const { videoModalIsOpen, setVideoModalIsOpen } = useVideoModal();

  const handleClick = () => {
    onClickSetVideoIndex();
    setVideoModalIsOpen(true);
  };

  return (
    <li
      tw="flex flex-col cursor-pointer"
      onClick={handleClick}
      onMouseEnter={() => setHasMouseOver(true)}
      onMouseLeave={() => setHasMouseOver(false)}
    >
      <Thumbnail
        imgSrc={videoThumbnail?.responsiveImage?.src}
        hoverImgSrc={videoThumbnailHover?.responsiveImage?.src}
        hasMouseOver={hasMouseOver}
      />

      <Name>{name}</Name>
      <span tw="text-white">{oneLiner}</span>
      <ul>
        {ecospheres?.map(({ ecoid, id }, i) => {
          if (filters?.includes(id)) return <li key={i}>{ecoid}</li>;
          return null;
        })}
      </ul>
    </li>
  );
};

function Portal({ children }) {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    const target = document.body;
    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el]);

  return createPortal(children, el);
}

const CompanyList = ({ companies, filters }) => {
  const { videoModalIsOpen, setVideoModalIsOpen } = useVideoModal();

  const [videoIndex, setVideoIndex] = useState(0);

  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <>
      {companies?.length > 0 && (
        <div>
          <h2 tw="mb-4 font-semibold opacity-60 text-center text-base">
            Showing {filters.length === 0 ? 'All Interviews' : 'Filtered Results'}
          </h2>
          <Grid>
            {companies?.map((props, i) => (
              <Card
                {...props}
                filters={filters}
                key={props.id}
                onClickSetVideoIndex={() => setVideoIndex(i)}
              />
            ))}
          </Grid>
        </div>
      )}
      {videoModalIsOpen && (
        <Portal>
          <Modal isOpen={videoModalIsOpen} setOpenModal={setVideoModalIsOpen}>
            <div tw="flex justify-between items-start gap-4 mb-8 px-6 2xl:px-0">
              <div>
                <Name>{companies[videoIndex]?.name}</Name>
                <span>{companies[videoIndex]?.oneLiner}</span>
                <A href={companies[videoIndex]?.website} tw="block underline">
                  {companies[videoIndex]?.website}
                </A>
              </div>
              <Close onClick={() => setVideoModalIsOpen(false)}>Close</Close>
            </div>
            <div tw="mx-5 2xl:mx-0">
              <Iframe
                url={companies[videoIndex].twentythreeEmbedUrl}
                paginate={paginate}
                direction={direction}
                videoTitle={companies[videoIndex]?.name}
              />
              <Controls>
                <div tw="flex w-full justify-between relative items-start mt-0 lg:(absolute top-0)">
                  <TiltButton
                    label="Previous video"
                    accent="#5ec2bb"
                    onClick={() => {
                      paginate(-1);
                      setVideoIndex((v) => v - 1);
                    }}
                    reverse
                    hide={videoIndex === 0}
                  />
                  <TiltButton
                    label="Next video"
                    accent="#5ec2bb"
                    onClick={() => {
                      paginate(1);
                      setVideoIndex((v) => v + 1);
                    }}
                    hide={videoIndex === companies.length - 1}
                  />
                </div>
              </Controls>
            </div>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default CompanyList;
