import React from 'react';
import tw, { css } from 'twin.macro';
import { Section, Inner, Half, PageTitle } from 'components';
import TiltButton from 'components/TiltButton';
import Illustrations from 'components/Illustrations/Illustrations';

const innerSectionStyles = css`
  ${tw`relative flex flex-col-reverse mt-10 md:mt-0`}
`;

const sectionStyles = css`
  ${tw`
  relative min-h-screen flex items-center justify-center flex flex-col`};
  :after {
    content: '';
    ${tw`absolute inset-0 h-full z-minus-1`}
    background-image: linear-gradient(0deg, #0a1236 0%, #203075 100%);
  }
`;

const illustrationContainerStyles = css`
  > div {
    ${tw`w-full`}
  }
  svg {
    max-height: 600px;
  }
  ${tw`lg:(absolute bottom-0 right-0 h-full w-1/2 flex items-center)`}
`;
const IllustrationContainer = ({ children }) => (
  <div css={illustrationContainerStyles}>{children}</div>
);

const HeroWith4CTA = ({ heading, bodyTextNode, callToActions, illustration }) => (
  <Section css={sectionStyles} show>
    <Inner css={innerSectionStyles}>
      <Half>
        <PageTitle>{heading}</PageTitle>
        <div
          tw="my-6"
          dangerouslySetInnerHTML={{ __html: bodyTextNode?.childMarkdownRemark.html }}
        />
      </Half>
      <IllustrationContainer>
        <Illustrations tech={illustration?.ecoid} />
      </IllustrationContainer>
    </Inner>
    <Inner>
      <ul tw="lg:(absolute bottom-20 z-10)">
        {callToActions?.map((cta) => (
          <TiltButton
            to={cta.href}
            label={`For ${cta.label}`}
            tw="w-full mt-4 lg:(w-auto mr-10 mt-0)"
            key={cta.href}
          />
        ))}
      </ul>
    </Inner>
  </Section>
);

export default HeroWith4CTA;
