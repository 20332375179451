import React from 'react';
import tw, { css } from 'twin.macro';
import { useOnScreen } from 'utils/hooks';

const floorStyles = css`
  z-index: -1;
  stroke: #0a1236;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  transform-origin: 50% 100%;
  transform: rotateY(-12deg) rotateX(70deg) translateY(12em) translateZ(5em) scale(2);
  transition: transform 3s ease-in-out;
`;

const floorVisibleStyles = css`
  transform: rotateY(0deg) rotateX(70deg) translateY(12em) translateZ(5em) scale(2);
`;

const GridFloor = () => {
  const [ref, visible] = useOnScreen();
  return (
    <svg
      ref={ref}
      css={[floorStyles, visible && floorVisibleStyles]}
      viewBox="0 0 1000 500"
      width="1000"
      height="500"
      fill="none"
      strokeWidth="0.5"
    >
      <line x1="-900" y1="0" x2="100" y2="500" />
      <line x1="-800" y1="0" x2="200" y2="500" />
      <line x1="-700" y1="0" x2="300" y2="500" />
      <line x1="-600" y1="0" x2="400" y2="500" />
      <line x1="-500" y1="0" x2="500" y2="500" />
      <line x1="-400" y1="0" x2="600" y2="500" />
      <line x1="-300" y1="0" x2="700" y2="500" />
      <line x1="-200" y1="0" x2="800" y2="500" />
      <line x1="-100" y1="0" x2="900" y2="500" />
      <line x1="0" y1="0" x2="1000" y2="500" />
      <line x1="100" y1="0" x2="1100" y2="500" />
      <line x1="200" y1="0" x2="1200" y2="500" />
      <line x1="300" y1="0" x2="1300" y2="500" />
      <line x1="400" y1="0" x2="1400" y2="500" />
      <line x1="500" y1="0" x2="1500" y2="500" />
      <line x1="600" y1="0" x2="1600" y2="500" />
      <line x1="700" y1="0" x2="1700" y2="500" />
      <line x1="800" y1="0" x2="1800" y2="500" />
      <line x1="900" y1="0" x2="1900" y2="500" />
      <line x1="-900" y1="500" x2="100" y2="0" />
      <line x1="-800" y1="500" x2="200" y2="0" />
      <line x1="-700" y1="500" x2="300" y2="0" />
      <line x1="-600" y1="500" x2="400" y2="0" />
      <line x1="-500" y1="500" x2="500" y2="0" />
      <line x1="-400" y1="500" x2="600" y2="0" />
      <line x1="-300" y1="500" x2="700" y2="0" />
      <line x1="-200" y1="500" x2="800" y2="0" />
      <line x1="-100" y1="500" x2="900" y2="0" />
      <line x1="0" y1="500" x2="1000" y2="0" />
      <line x1="100" y1="500" x2="1100" y2="0" />
      <line x1="200" y1="500" x2="1200" y2="0" />
      <line x1="300" y1="500" x2="1300" y2="0" />
      <line x1="400" y1="500" x2="1400" y2="0" />
      <line x1="500" y1="500" x2="1500" y2="0" />
      <line x1="600" y1="500" x2="1600" y2="0" />
      <line x1="700" y1="500" x2="1700" y2="0" />
      <line x1="800" y1="500" x2="1800" y2="0" />
      <line x1="900" y1="500" x2="1900" y2="0" />
    </svg>
  );
};

export default GridFloor;
