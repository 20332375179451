import React from 'react';
import tw from 'twin.macro';
import { Section, Inner, SectionTitle } from 'components';
import Label from 'components/Label';

const Heading = tw.h1`font-semibold m-0 leading-tight text-xl sm:text-4xl`;

const ArticleHeader = ({ heading, shortIntroNode, date }) => {
  return (
    <Section tw="pb-0 sm:pb-0">
      <Inner tw="mt-32 mx-auto sm:max-w-5xl">
        <Label>{date.createdAt}</Label>
        <Heading tw="my-2">{heading}</Heading>
        <div dangerouslySetInnerHTML={{ __html: shortIntroNode.childMarkdownRemark.html }} />
      </Inner>
    </Section>
  );
};

export default ArticleHeader;
