import React from 'react';
import ArticleHeader from 'modules/ArticleHeader';
import Accordion from 'modules/Accordion';
import EcospheresTabs from 'modules/EcospheresTabs';
import Events from 'modules/Events';
import FactBoxes from 'modules/FactBoxes';
import HeroWith4CTA from 'modules/HeroWith4CTA';
import HeroWithIllustration from 'modules/HeroWithIllustration';
import ImageFullModule from 'modules/ImageFullModule';
import InPartnershipWith from 'modules/InPartnershipWith';
import NewsletterSlim from 'modules/NewsletterSlim';
import RelatedRibbon from 'modules/RelatedRibbon';
import StickyCTA from 'modules/StickyCTA';
import TabsWithIllustrations from 'modules/TabsWithIllustrations';
import Team from 'modules/Team';
import Text from 'modules/Text';
import TextAndImage from 'modules/TextAndImage';
import TextAndVideo from 'modules/TextAndVideo';
import Timeline from 'modules/Timeline';
import ColumnsAndCTA from 'modules/ColumnsAndCTA';
import Layout from 'templates/Layout';
import VideosGrid from 'modules/IndustriensFondVideos/VideosGrid';
import StartupDataBubbles from 'modules/StartupDataBubbles';
// import FunnelSelectCTA from '../modules/FunnelSelectCTA';

function PageTemplate(data) {
  if (!data?.blocks) return null;
  const components = data.blocks.map((block, i) => {
    if (block?.model) {
      const { apiKey } = block.model;
      const key = apiKey + i;

      switch (apiKey) {
        case 'article_header':
          return <ArticleHeader {...block} heading={data.heading} date={data.date} key={key} />;
        case 'hero_with_illustration':
          return <HeroWithIllustration {...block} key={key} />;
        case 'hero_with_four_cta':
          return <HeroWith4CTA {...block} key={key} />;
        case 'text':
          return <Text {...block} key={key} />;
        case 'text_image':
          return <TextAndImage {...block} key={key} />;
        case 'text_video':
          return <TextAndVideo {...block} key={key} />;
        case 'sticky_call_to_action':
          return <StickyCTA {...block} key={key} />;
        case 'accordion':
          return <Accordion {...block} key={key} />;
        case 'columns_and_cta':
          return <ColumnsAndCTA {...block} key={key} />;
        case 'ecospheres_tab':
          return <EcospheresTabs {...block} key={key} />;
        case 'fact_box':
          return <FactBoxes {...block} key={key} />;
        case 'image_full_module':
          return <ImageFullModule {...block} key={key} />;
        case 'events_module':
          return <Events {...block} key={key} />;
        case 'newsletter_slim':
          return <NewsletterSlim {...block} key={key} />;
        case 'related_ribbon':
          return <RelatedRibbon {...block} key={key} />;
        case 'in_partnership_with':
          return <InPartnershipWith {...block} key={key} />;
        case 'timeline':
          return <Timeline {...block} key={key} />;
        // case 'funnel_select_call_to_action':
        //   return <FunnelSelectCTA {...block} key={key} />;
        case 'tabs_with_illustration':
          return <TabsWithIllustrations {...block} key={key} />;
        case 'team':
          return <Team {...block} key={key} />;
        case 'startup_data_bubble':
          return <StartupDataBubbles {...block} key={key} />;
        case 'videos_grid_if':
          return <VideosGrid {...block} key={key} />;
        default:
          break;
      }
    }
  });

  return <Layout seo={data.seo}>{components}</Layout>;
}

export default PageTemplate;
