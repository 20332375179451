import React, { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { Section, Inner, SectionTitle, A } from 'components';
import AccordionItem from 'components/AccordionItem';
import AccordionArrow from 'icons/AccordionArrow';

const EventsList = tw.div`mt-6`;
const titleStyles = tw`
  py-3 transition-colors duration-300 ease-linear text-xl leading-none pt-0 sm:py-0
`;
const titleExpandedStyles = css`
  ${tw`text-green pb-2`}
  h3 {
    ${tw`opacity-100`}
  }
`;
const arrowStyles = tw`
  text-red transition-transform duration-300 ease-linear transform
`;

const Events = ({ heading, bodyText, preSelectedTypesOrTags }) => {
  const { allDatoCmsEvent } = useStaticQuery(eventsQuery);
  const allEvents = allDatoCmsEvent.edges.map((e) => e.node);
  const [activeTab, setActiveTab] = useState();
  const [expanded, setExpanded] = useState();
  const [events, setEvents] = useState();

  const handleExpand = (event, idx) => {
    const closeItself = expanded === idx && event === activeTab;
    setActiveTab(event);
    setExpanded(closeItself ? null : idx);
  };

  useEffect(() => {
    // ! This logic only works for our "limited events" - if we do BIG API collection of all DK events
    // ! this module needs to be re-done w/ right queries
    const hasPreSelection = preSelectedTypesOrTags.length > 0;

    let allEventsArr = allEvents;

    if (hasPreSelection) {
      const filterLabels = preSelectedTypesOrTags.map((item) => item.slug);

      const arr = [];

      allEvents.filter((evt) => {
        if (
          filterLabels.some((id) => evt.tags.some((tag) => tag.slug === id)) ||
          filterLabels.some((id) => evt.categories.some((tag) => tag.slug === id))
        )
          arr.push(evt);
      });
      allEventsArr = arr;
    }

    // ! Only display future events (create conditional if you want to have "show all")
    const todayDateTime = new Date();
    const upcomingEventsOnly = allEventsArr.filter(
      ({ startDateTime }) => new Date(startDateTime).getTime() > todayDateTime
    );
    setEvents(upcomingEventsOnly);
  }, []);

  return (
    <Section id="events">
      <Inner>
        <SectionTitle>{heading}</SectionTitle>
        {bodyText && <p tw="mt-2">{bodyText}</p>}
        <EventsList>
          {events?.map(({ label, date, day, month, bodyText, eventUrl, buyTicketUrl }, i) => {
            const isExpanded = expanded === i;
            return (
              <React.Fragment key={i}>
                <button
                  onClick={() => handleExpand(label, i)}
                  tw="w-full flex justify-between items-center cursor-pointer text-left"
                >
                  <div
                    css={[
                      tw`flex flex-col sm:(flex-row items-center)`,
                      isExpanded && titleExpandedStyles,
                    ]}
                  >
                    <div tw="flex">
                      <h3 tw="w-5 sm:w-8 opacity-50 text-xs sm:text-base">{day}</h3>
                      <h3 tw="sm:w-28 opacity-50 text-xs sm:text-base">{month}</h3>
                    </div>
                    <h2 css={titleStyles}>{label}</h2>
                  </div>
                  <AccordionArrow css={[arrowStyles, isExpanded && tw`rotate-180`]} />
                </button>
                <AccordionItem i={i} expanded={isExpanded}>
                  <div tw="mb-2">
                    <StructuredText data={bodyText} />
                  </div>
                  {eventUrl && (
                    <div tw="font-bold">
                      <span tw="mr-2">Event website: </span>
                      <A href={eventUrl.href}>{eventUrl.label}</A>
                    </div>
                  )}
                  {buyTicketUrl && (
                    <div tw="font-bold">
                      <span tw="mr-2">Get ticket(s): </span>
                      <A href={buyTicketUrl.href}>{buyTicketUrl.label}</A>
                    </div>
                  )}
                </AccordionItem>
              </React.Fragment>
            );
          })}
        </EventsList>
      </Inner>
    </Section>
  );
};

export default Events;

const eventsQuery = graphql`
  {
    allDatoCmsEvent(sort: { fields: startDateTime, order: ASC }) {
      edges {
        node {
          label
          startDateTime
          date: startDateTime(formatString: "DD MMMM")
          day: startDateTime(formatString: "DD")
          month: startDateTime(formatString: "MMMM")
          time: startDateTime(formatString: "HH:mm")
          year: startDateTime(formatString: "YYYY")
          bodyText {
            value
          }
          eventUrl {
            label
            href
          }
          buyTicketUrl {
            label
            href
          }
          categories {
            label
            slug
          }
          tags {
            label
            slug
          }
        }
      }
    }
  }
`;
