import React from 'react';
import tw from 'twin.macro';
import { Section, Inner, SectionTitle } from 'components';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImageFullModule = ({ heading, bodyTextNode, caption, image }) => (
  <Section tw="py-0 sm:py-12">
    <Inner>
      {heading && <SectionTitle>{heading}</SectionTitle>}
      {bodyTextNode && (
        <div
          tw="mb-6"
          dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
        />
      )}
      <div>
        <GatsbyImage image={image.gatsbyImageData} placeholder="blurred" alt={caption} />
      </div>
      {caption && <span>{caption}</span>}
    </Inner>
  </Section>
);

export default ImageFullModule;
