import React, { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { graphql, useStaticQuery, Link } from 'gatsby';
import {
  Section,
  Inner,
  SectionHeader,
  SectionTitle,
  Half,
  PageTitle,
  A,
  contentStyles,
} from 'components';
import TiltTabsList from 'components/TiltTabsList';
import FadeIn from 'components/FadeIn';
import LinkArrow from 'components/Icons/LinkArrow';
import GridFloor from 'components/Icons/GridFloor';
import Illustrations from 'components/Illustrations/Illustrations';
import { useOnScreen } from 'utils/hooks';

const gradientStyles = css`
  ${tw`relative overflow-hidden`}

  &:before {
    content: '';
    ${tw`w-full h-full top-0 left-0 z-minus-1 absolute`}
    background-image: linear-gradient(180deg, #0a1236 50%, #203075 100%);
  }
`;

const Container = styled.div`
  height: 620px;
  ${tw`flex w-full md:h-105 relative mt-10 max-w-5xl mx-auto`}
`;

const fadeInStyles = css`
  ${tw`w-full absolute h-full flex flex-col md:flex-row`}

  svg {
    ${tw`w-full h-full`}
  }
`;

const learnMoreStyles = css`
  ${tw`mt-6 inline-block no-underline`}
  svg {
    ${tw`ml-2 transform scale-100 origin-left transition-transform`}
    width: 24px;
  }
  &:hover svg {
    ${tw`transform scale-125`}
  }
`;

const EcospheresTabs = ({ heading, bodyText }) => {
  const {
    allDatoCmsEcosphere: { edges },
  } = useStaticQuery(ecospheresQuery);
  const ecospheres = edges.map((eco) => eco.node).sort((a, b) => a.position - b.position);
  const [ref, visible] = useOnScreen();
  const [activeTab, setActiveTab] = useState(ecospheres[0]);

  const link = activeTab.readyToPublish ? `/${activeTab.icon.basename}` : activeTab.clusterWebsite;

  return (
    <Section css={gradientStyles}>
      <Inner>
        <SectionHeader tw="sm:flex-col mb-6">
          <SectionTitle>{heading}</SectionTitle>
          <p tw="mt-5">{bodyText}</p>
        </SectionHeader>
        <TiltTabsList list={ecospheres} onClick={setActiveTab} activeTab={activeTab} />
        <Container ref={ref}>
          <FadeIn id={activeTab.icon.basename} visible={visible} css={fadeInStyles}>
            <Half css={contentStyles} tw="pt-8 md:pt-12">
              <PageTitle tw="towards text-5xl w-80">{activeTab.label}</PageTitle>
              <div tw="md:pl-12">
                <p tw="mb-4 text-s">{activeTab.clusterBio}</p>
                <p>
                  <strong tw="text-green text-2xl">{activeTab.numberOfExponentialStartups}</strong>{' '}
                  exponential startups <br />
                  <span tw="italic opacity-75 text-sm">Source: {activeTab.clusterName}</span>
                </p>
                {activeTab?.icon?.basename && (
                  <Link to={link} css={learnMoreStyles}>
                    Learn More
                    <LinkArrow />
                  </Link>
                )}
              </div>
            </Half>
            <Half tw="pt-6 md:pt-0">
              <Illustrations tech={activeTab.icon.basename} />
            </Half>
          </FadeIn>
        </Container>
      </Inner>
      <GridFloor />
    </Section>
  );
};

const ecospheresQuery = graphql`
  {
    allDatoCmsEcosphere(filter: { showInEcospheresTabs: { eq: true } }) {
      edges {
        node {
          label
          position
          icon {
            basename
          }
          clusterName
          clusterBio
          clusterWebsite
          readyToPublish
          numberOfExponentialStartups
        }
      }
    }
  }
`;

export default EcospheresTabs;
