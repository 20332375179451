import React from 'react';
import { graphql } from 'gatsby';
import PageTemplate from 'templates/PageTemplate';

const NotFoundPage = ({ data: { datoCmsMainSite } }) => <PageTemplate {...datoCmsMainSite} />;

export default NotFoundPage;

export const notFoundQuery = graphql`
  {
    datoCmsMainSite(title: { eq: "404" }) {
      blocks {
        ... on DatoCmsHeroWithFourCta {
          model {
            apiKey
          }
          heading
          bodyTextNode {
            childMarkdownRemark {
              html
            }
          }
          callToActions {
            label
            href
          }
          illustration {
            ecoid
          }
        }
      }
    }
  }
`;
