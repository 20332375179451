import { createContext, useState, useContext } from 'react';

const VideoModalContext = createContext();

const VideoModalProvider = ({ children }) => {
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);

  const store = {
    videoModalIsOpen,
    setVideoModalIsOpen,
  };

  return <VideoModalContext.Provider value={store}>{children}</VideoModalContext.Provider>;
};

export default VideoModalProvider;

export const useVideoModal = () => {
  const result = useContext(VideoModalContext);
  if (!result) {
    throw new Error('useVideoModal must be used in VideoModalContext');
  }
  return result;
};
