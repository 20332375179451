import React from 'react';
import tw from 'twin.macro';
import { Section, Inner, SectionTitle } from 'components';
import TiltButton from 'components/TiltButton';

const ColumnsAndCTA = ({ heading, bodyTextNode, columns, callToAction }) => (
  <Section tw="sm:py-10">
    <Inner>
      <SectionTitle>{heading}</SectionTitle>
      {bodyTextNode && (
        <div dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }} />
      )}
      <ul tw="grid gap-6 my-6 md:(my-12 grid-cols-4)">
        {columns.map((c) => (
          <li key={c.heading}>
            <h3 tw="text-s md:h-12 xl:h-8 leading-tight">{c.heading}</h3>
            <p>{c.bodyText}</p>
          </li>
        ))}
      </ul>
      <div tw="text-center">
        <TiltButton to={callToAction?.href} label="Read more" />
      </div>
    </Inner>
  </Section>
);

export default ColumnsAndCTA;
