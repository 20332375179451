import React from 'react';
import tw from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { A, Inner, Section, SectionTitle } from 'components/index';

const InPartnershipWith = ({ heading }) => {
  const {
    allDatoCmsPartnership: { edges },
  } = useStaticQuery(
    graphql`
      query partnershipsQuery {
        allDatoCmsPartnership {
          edges {
            node {
              position
              logoInWhiteEn {
                url
              }
              label
              website
            }
          }
        }
      }
    `
  );
  const partners = edges.map((p) => p.node);
  partners.sort((a, b) => a.position - b.position);
  return (
    <Section>
      <Inner>
        <SectionTitle tw="mb-10">{heading}</SectionTitle>
        <ul tw="justify-center grid gap-6 md:gap-16 grid-cols-2 md:grid-cols-4">
          {partners.map(({ label, logoInWhiteEn, website }) => (
            <li key={website} tw="flex items-center justify-center">
              <A href={website}>
                <img src={logoInWhiteEn.url} alt={label + ' logo'} tw="max-w-full" />
              </A>
            </li>
          ))}
        </ul>
      </Inner>
    </Section>
  );
};

export default InPartnershipWith;
