import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import tw, { css } from 'twin.macro';
import GlueFrame from 'glueframe';

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const iframeContainerStyles = css`
  ${tw`relative overflow-hidden w-full`}
  padding-top: 56.25%;
`;

const iframeStyles = tw`absolute inset-0 w-full h-full`;

function Iframe({ url, direction, paginate, videoTitle }) {
  const ref = useRef();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const iframe = new GlueFrame(ref.current, 'Player');
      iframe.bind('player:video:playing', () => {
        window.dataLayer = window.dataLayer || [];

        const firstPlay = !window.dataLayer.some(({ event }) => event === 'video_play');
        const sameVideo = window.dataLayer.some((item) => item.video_title === videoTitle);

        if (firstPlay || !sameVideo) {
          window.dataLayer.push({
            event: 'video_play',
            video_title: videoTitle,
          });
        }

        /* if (window.gtag) {
          window.gtag('event', 'video_play', {
            event_category: 'video_play',
            event_label: videoTitle,
          });
        } */
      });
    }
  }, [videoTitle]);

  return (
    <div css={iframeContainerStyles}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.iframe
          ref={ref}
          key={url}
          src={url}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          css={iframeStyles}
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
    </div>
  );
}

export default Iframe;
