import React from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Section, Inner, SectionTitle, A } from 'components/index';

const Grid = tw.ul`grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-8 mt-6`;

const Person = ({
  email,
  name,
  jobTitle,
  image,
  role,
  telephone,
  linkedin,
  twitter,
  organisation,
}) => (
  <li tw="mb-4">
    {role === 'Team' && image?.gatsbyImageData && (
      <GatsbyImage image={image.gatsbyImageData} placeholder="blurred" alt={name} />
    )}
    <h3 tw="mt-2">{name}</h3>
    <h3>{jobTitle}</h3>
    {organisation && <p>{organisation}</p>}
    {role === 'Team' && (
      <>
        <a href={`mailto:${email}`} tw="block mt-2">
          {email}
        </a>
        <a href={`tel:${telephone}`} tw="block mb-2">
          {telephone}
        </a>
        <div tw="flex">
          {twitter && (
            <A href={twitter} tw="block underline mr-3">
              Twitter
            </A>
          )}
          {linkedin && (
            <A href={linkedin} tw="block underline">
              LinkedIn
            </A>
          )}
        </div>
      </>
    )}
  </li>
);

const Team = ({ heading, role, selectIndividualPeople, people }) => {
  const { allDatoCmsDhdTeam } = useStaticQuery(teamQuery);
  const team = selectIndividualPeople
    ? people
    : allDatoCmsDhdTeam.edges
        .filter((t) => t.node.role === role)
        .map((person) => person.node)
        .sort((a, b) => a.position - b.position);
  return (
    <Section>
      <Inner>
        <SectionTitle>{heading}</SectionTitle>
        <Grid>
          {team.map((p) => (
            <Person {...p} />
          ))}
        </Grid>
      </Inner>
    </Section>
  );
};

export default Team;

export const teamQuery = graphql`
  {
    allDatoCmsDhdTeam {
      edges {
        node {
          role
          name
          jobTitle
          organisation
          email
          telephone
          linkedin
          twitter
          image {
            gatsbyImageData(placeholder: BLURRED, width: 410)
          }
          position
        }
      }
    }
  }
`;
