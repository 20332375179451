import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Section, Inner } from 'components';
import Results from './Results';
import VideoModalProvider from 'utils/hooks/use-video-modal';

const Grid = tw.div`grid w-full gap-5 grid-cols-3 md:grid-cols-5`;
const EcoLabel = tw.h3`text-white text-sm font-bold`;

const buttonStyles = tw`text-white bg-white-20 rounded-full py-3 border border-solid border-white font-semibold text-base cursor-pointer text-sm xl:text-base hover:(text-white bg-white-50)`;
const activeButtonStyles = tw`bg-white text-blue-dark`;

const Button = ({ label, tech, isActive, onClick }) => {
  if (label === 'Artificial Intelligence') label = 'AI';
  return (
    <button onClick={onClick} css={[buttonStyles, isActive && activeButtonStyles]}>
      {label}
    </button>
  );
};

const Filter = ({ filter, onSelect }) => {
  const { allDatoCmsEcosphere } = useStaticQuery(ecospheresQuery);
  const ecospheres = allDatoCmsEcosphere.edges.map((eco) => eco.node);

  return (
    <Grid tw="mb-10">
      {ecospheres?.map(({ id, label, ecoid }) => {
        const clientSideID = id.replace('DatoCmsEcosphere-', '').replace('-en', '');
        return (
          <Button
            label={label}
            onClick={() => onSelect(clientSideID)}
            isActive={filter?.includes(clientSideID)}
            tech={ecoid}
            key={clientSideID}
          />
        );
      })}
    </Grid>
  );
};

const VideosGrid = ({ milestones }) => {
  const [filter, setFilter] = useState([]);

  const onSelect = (id) => {
    const arr = [...filter];
    arr.includes(id) ? arr.splice(arr.indexOf(id), 1) : arr.push(id);
    setFilter(arr);
  };

  return (
    <Section id="videos">
      <Inner>
        <Filter onSelect={onSelect} filter={filter} />
        <VideoModalProvider>
          <Results variable={filter.length > 0 ? filter : []} />
        </VideoModalProvider>
      </Inner>
    </Section>
  );
};

export default VideosGrid;

export const ecospheresQuery = graphql`
  {
    allDatoCmsEcosphere(filter: { showInIfVideos: { eq: true } }, sort: { fields: position }) {
      edges {
        node {
          id
          label
          ecoid
          icon {
            url
          }
          clusterPrimaryColor {
            hex
          }
        }
      }
    }
  }
`;
