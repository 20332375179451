import { useState, useEffect } from 'react';
import tw from 'twin.macro';
import { useAsyncFetch, request } from 'utils/hooks/use-async-fetch';
import CompanyList from './CompanyList';

const Placeholder = tw.div`bg-[#ffffff05] flex-center p-20 rounded-xl`;

const Results = ({ variable }) => {
  const { data, status, error, run } = useAsyncFetch({
    status: variable ? 'pending' : 'idle',
  });
  const [resultMatches, setResultMatches] = useState([]);

  useEffect(() => {
    if (data) {
      const filters = variable;
      const oneMatch = [];
      const multipleMatch = [];
      const { allCompanies } = data;

      allCompanies.map((co) => {
        const intersection = co.ecospheres.filter((value) => filters?.includes(value.id));
        intersection.length === 1 ? oneMatch.push(co) : multipleMatch.push(co);
      });

      const combinedMatches = [...multipleMatch, ...oneMatch];

      setResultMatches(combinedMatches);
    }
  }, [variable, data]);

  useEffect(() => {
    if (!variable) return;
    const params = {
      query: `
        query Companies($ids: [ItemId]) {
          allCompanies(first: 100, filter: {ecospheres: {anyIn: $ids }, twentythreeEmbedUrl: {isBlank: "false"}}) {
            id
            name
            oneLiner
            website
            twentythreeEmbedUrl
            videoThumbnail {
              responsiveImage(imgixParams: {w: "320"}) {
                src
              }
            }
            videoThumbnailHover {
              responsiveImage(imgixParams: {w: "320"}) {
                src
              }
            }
            ecospheres {
              ecoid
              id
            }
          }
        }
      `,
      variables: {
        ids: variable,
      },
    };
    run(request(params));
  }, [variable, run]);

  if (status === 'idle') {
    return (
      <Placeholder>Select some of the fields above to see relevant startup interviews</Placeholder>
    );
  } else if (status === 'pending') {
    return <Placeholder tw="text-green">LOADING INTERVIEWS</Placeholder>;
  } else if (status === 'rejected') {
    throw error;
  } else if (status === 'resolved') {
    return <CompanyList companies={resultMatches} filters={variable} />;
  }
  throw new Error('This should be impossible');
};

export default Results;
