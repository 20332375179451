import React, { useState } from 'react';
import tw from 'twin.macro';
import { StructuredText } from 'react-datocms';
import { Section, Inner, SectionTitle } from 'components';
import AccordionItem, { AccordionHead } from 'components/AccordionItem';
import Illustrations from 'components/Illustrations/Illustrations';

const Topics = tw.div`text-left w-full md:w-3/5`;
const Illustration = tw.div`mt-10 w-3/5 md:(mt-0 w-2/5)`;

const Accordion = ({ heading, bodyText, topics, illustration }) => {
  const [activeTab, setActiveTab] = useState();
  const [expanded, setExpanded] = useState(10);

  const handleExpand = (q, idx) => {
    const closeItself = expanded === idx && q === activeTab;
    setActiveTab(q);
    setExpanded(closeItself ? null : idx);
  };

  return (
    <Section>
      <Inner>
        <SectionTitle>{heading}</SectionTitle>
        {bodyText && <p>{bodyText}</p>}
        <div tw="flex items-center flex-col-reverse md:(flex-row gap-6)">
          <Topics>
            {topics?.map(({ heading, bodyText }, i) => {
              const isExpanded = expanded === i;
              return (
                <React.Fragment key={i}>
                  <AccordionHead
                    setExpanded={() => handleExpand(heading, i)}
                    expanded={isExpanded}
                    label={heading}
                  />
                  <AccordionItem i={i} expanded={isExpanded}>
                    <StructuredText data={bodyText} />
                  </AccordionItem>
                </React.Fragment>
              );
            })}
          </Topics>
          <Illustration>
            <Illustrations tech={illustration.ecoid} />
          </Illustration>
        </div>
      </Inner>
    </Section>
  );
};

export default Accordion;
