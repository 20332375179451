import React, { useEffect, useRef } from 'react';
import tw, { css, styled } from 'twin.macro';
import VanillaTilt from 'vanilla-tilt';

const Tab = styled.li(({ active }) => [
  css`
    ${tw`relative inline-block w-1/3 md:w-32`}
    box-shadow: 0px 10px 10px #00000025;

    @media only screen and (max-width: 768px) {
      transform: none !important;
    }
    button {
      ${tw`block py-3 w-full flex justify-center items-center border border-solid border-white-50 cursor-pointer outline-none focus:(border-green) hover:(border-white)`}
    }
  `,
  active &&
    css`
      button {
        ${tw`border-white font-bold text-white`}
      }
    `,
]);

const Tabs = tw.ul`my-0 mx-auto p-0 w-full flex flex-wrap md:justify-center`;

const TiltTab = (props) => {
  const tiltRef = useRef();
  const { activeTab, label, onClick } = props;

  useEffect(() => {
    const tiltOptions = {
      max: 12,
      speed: 1800,
      reverse: false,
      glare: true,
      'max-glare': 0.5,
      gyroscope: false,
    };

    VanillaTilt.init(tiltRef.current, tiltOptions);
  }, []);

  return (
    <Tab ref={tiltRef} active={activeTab?.label === label}>
      <button onClick={() => onClick(props)}>{label}</button>
    </Tab>
  );
};

const TiltTabsList = ({ list, onClick, activeTab }) => (
  <Tabs>
    {list.map((props, i) => (
      <TiltTab {...props} activeTab={activeTab} onClick={onClick} key={i} />
    ))}
  </Tabs>
);

export default TiltTabsList;
