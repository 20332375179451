import React, { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { Section, Inner, Half, SectionTitle, SectionHeader, contentStyles } from 'components';
import { useOnScreen, useWindowSize } from 'utils/hooks';
import { capitalizeFirstChar } from 'utils';
import Illustrations from 'components/Illustrations/Illustrations';
import FadeIn from 'components/FadeIn';
import AccordionItem, { AccordionHead } from 'components/AccordionItem';

const TabBody = tw.div`bg-blue p-12 flex`;
const InnerBox = tw.div`bg-blue-between py-10 px-12`;
const innerBoxStyles = css`
  ${tw`text-sm`}
  a {
    ${tw`text-sm font-semibold`}
    margin-right: 18px;

    &:after {
      margin-left: 2px;
    }
  }
`;
const accordionBodyTextStyles = css`
  h2 {
    ${tw`font-bold leading-tight mb-2`}
  }
  p:last-of-type {
    ${tw`mb-0`}
  }
  img {
    ${tw`mx-auto`}
  }
`;
const illustrationStyles = css`
  ${tw`hidden sm:block absolute w-1/2 right-0 z-minus-1`}
  > div {
    ${tw`text-right`}
  }
  svg {
    ${tw`ml-auto`}
    max-height: 420px;
  }
`;
const TabButtons = tw.div`w-full flex justify-between`;
const TabButton = styled.button(({ active }) => [
  css`
    width: 32%;
    ${tw`bg-blue-between`}
  `,
  active && tw`bg-blue py-8`,
]);

const TabsWithIllustrations = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { bodyTextNode, tabLabel, innerBoxHeading, innerBoxBodyTextNode } = activeTab;
  const tech = activeTab.illustration.ecoid;
  const [expanded, setExpanded] = useState(10);

  const [ref, visible] = useOnScreen();
  const { width } = useWindowSize();

  const handleExpand = (item, idx) => {
    const closeItself = expanded === idx && item.tabLabel === activeTab.tabLabel;
    setActiveTab(item);
    setExpanded(closeItself ? null : idx);
  };

  // useEffect(() => {
  //   if (visible) setTimeout(() => setExpanded(0), 500);
  // }, [visible]);

  // Mobile Accordion View
  if (width && width < 1024) {
    return (
      <Section>
        <SectionTitle tw="mb-4" ref={ref}>
          Visit us and learn about
        </SectionTitle>
        {tabs.map((item, i) => {
          const isExpanded = expanded === i;
          return (
            <>
              <AccordionHead
                setExpanded={() => handleExpand(item, i)}
                expanded={isExpanded}
                label={capitalizeFirstChar(item.headingLabel)}
              />
              <AccordionItem i={i} expanded={isExpanded}>
                <div tw="w-4/5">
                  <Illustrations tech={tech} />
                </div>
                <InnerBox tw="py-3 px-5 mb-3">
                  <h4 tw="text-xl font-semibold mb-4">{innerBoxHeading}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: innerBoxBodyTextNode.childMarkdownRemark.html,
                    }}
                    css={[contentStyles, innerBoxStyles]}
                  />
                </InnerBox>
                <div
                  css={[contentStyles, accordionBodyTextStyles]}
                  dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
                />
              </AccordionItem>
            </>
          );
        })}
      </Section>
    );
  }

  return (
    <Section tw="pt-0 md:mt-84">
      <Inner>
        <div tw="flex justify-between items-end relative">
          <SectionHeader ref={ref}>
            <div>
              <SectionTitle>Visit us and learn about</SectionTitle>
              <SectionTitle tw="text-green">{tabLabel}</SectionTitle>
            </div>
          </SectionHeader>
          <FadeIn visible={visible} id={tech} css={illustrationStyles}>
            <Illustrations tech={tech} />
          </FadeIn>
        </div>
        <TabButtons>
          {tabs.map((tab) => (
            <TabButton
              onClick={() => setActiveTab(tab)}
              active={tab.tabLabel === activeTab?.tabLabel}
              key={tab.tabLabel}
            >
              <h3 tw="text-2xl font-bold">{tab.tabLabel}</h3>
            </TabButton>
          ))}
        </TabButtons>
        <TabBody>
          <Half tw="pr-5">
            <InnerBox>
              <h4 tw="text-xl font-semibold mb-4">{innerBoxHeading}</h4>
              <div
                css={contentStyles}
                dangerouslySetInnerHTML={{ __html: innerBoxBodyTextNode.childMarkdownRemark.html }}
              />
            </InnerBox>
          </Half>
          <Half>
            <div
              css={contentStyles}
              tw="pl-5"
              dangerouslySetInnerHTML={{ __html: bodyTextNode.childMarkdownRemark.html }}
            />
          </Half>
        </TabBody>
      </Inner>
    </Section>
  );
};

export default TabsWithIllustrations;
